<template>
  <div>
    <label 
      :for="locationInput?.id" 
      class="form-label text-center"
      :class="!canEdit ? 'opacity-25' : 'opacity-100'"
      >{{ labelText }}</label>
    <div class="d-flex flex-row">
      <span class="flex-grow-1">
        <input
          class="ertp-form-control" 
          :class="!canEdit ? 'opacity-25' : 'opacity-100'"
          v-uid
          ref="locationInput"
          :placeholder="props.placeholder"
          type="text" 
          :disabled="!canEdit" 
          :aria-describedby="helpTextRef?.id"
          @change="onChange"
        >       
      </span>
      <span class="border border-rounded rounded-end ertp-calendar-button">
        <slot></slot>
      </span>
    </div>
    <div v-if="!icon" 
      v-uid 
      ref="helpTextRef" 
      class="form-text"
      :class="!canEdit ? 'opacity-25' : 'opacity-100'"
      >
      {{ helpText }}
    </div>
    <div v-else>
      <a :href="iconUrl + '?utm_source=epicroadtripplanner'" target="_blank">
        <img
          class="img-fluid w-100 mt-3"
          :src=icon
          loading="lazy"
        />
      </a>
    </div>
    <div ref="attrContainer"></div>
  </div>
</template>

<script lang="ts">
import { descriptionName, navigationName } from "../../utilities/nameHelpers"

export type ERTPLocation = {
  /**
   * Name of this location, e.g. from user input, Google autocomplete Place details, etc.
   */
  userProvidedName?: string,

  /**
   * Navigation-friendly name of this location; for instance, if the user input is a full address, this will be the full address identical to the userProvidedName.
   */
  navigationName?: string,

  /**
   * AI description-friendly name of this location; for instance, if the user input is a full address, this will be the city/state portion only.
   */
  descriptionName?: string,
  googlePlaceId?: string,
  googlePlaceTypes?: Array<string>,
  latLng?: {
    lat: number,
    lng: number
  }
};

export function fromPlaceResult(userProvidedName: string, placeResult: google.maps.places.PlaceResult): ERTPLocation {

  const latLng =  placeResult.geometry?.location ? {
    lat: placeResult.geometry.location.lat(),
    lng: placeResult.geometry.location.lng()
  } : undefined;

  return {
    userProvidedName,
    navigationName: navigationName(placeResult, userProvidedName),
    descriptionName: descriptionName(placeResult),
    googlePlaceId: placeResult.place_id,
    googlePlaceTypes: placeResult.types,
    latLng, 
  }
}
</script>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { GoogleService } from '../../services/googleService';
import { injectRequired } from '../../utilities/injectRequired';

const props = defineProps<{
  labelText: string,
  placeholder?: string
  helpText: string,
  modelValue: ERTPLocation,
  gpid?: string  // optional Google Place Id to use to pre-populate this location input
  readOnly?: boolean
}>();

const emit = defineEmits<{
  'update:modelValue': [location: ERTPLocation]
}>();

const attrContainer = ref<HTMLDivElement>();
const locationInput = ref<HTMLInputElement>();
const helpTextRef = ref();
const icon = ref<string>();
const iconUrl = ref<string>();

const canEdit = computed(() => { 
  if (props.modelValue == undefined) return false;
  if (props.readOnly) return false;
  return true;
});


const googleService = injectRequired<GoogleService>('googleService');
// See "fields" in https://developers.google.com/maps/documentation/javascript/place-autocomplete
const GOOGLE_AUTOCOMPLETE_INIT_FIELDS = ['name', "place_id", "types", "address_components", "geometry.location", "formatted_address"];
// See https://developers.google.com/maps/documentation/javascript/supported_types#table3
const GOOGLE_AUTOCOMPLETE_TYPE_CONSTRAINT: Array<string> = [];


function onChange(event: any) {
  emit('update:modelValue', {userProvidedName: event.target.value});
  return true;
}

type LocationDetails = {
  icon: string | undefined;
  iconUrl: string | undefined;
  length: number
}

function locationDetailsfromGooglePlaceDetails(
  placeResult: google.maps.places.PlaceResult | null, 
  index: number): LocationDetails {
    const photos = placeResult?.photos;
    let icon: string | undefined;
    let iconUrl: string | undefined;
    const length = placeResult?.photos?.length || 0;
  
    if (photos) {
      icon = photos[index].getUrl({maxWidth: 800, maxHeight: 600});
    }
    if(!icon) {
      icon = placeResult?.icon;
    }
    iconUrl = placeResult?.website;
    if (!iconUrl) {
      iconUrl = placeResult?.url;
    }

    return {
      icon,
      iconUrl,
      length,
    }
  }

const GOOGLE_PLACEID_INIT_FIELDS = ['name', "types", "address_components", "geometry.location", 'photos', 'icon', 'website', 'url'];

function initializeWithGooglePlaceId(inputField: HTMLInputElement, placeId: string) {

  if (!attrContainer.value) { console.log(`Waiting for container to be mounted so that we can set attributions...`); return; }
  
  const request = {
    placeId,
    fields: GOOGLE_PLACEID_INIT_FIELDS
  };

  const placesService = googleService.getPlacesService(attrContainer.value);
  placesService.getDetails(request, (details) => {
    
    const result = locationDetailsfromGooglePlaceDetails(details, 0);
    icon.value = result.icon;
    iconUrl.value = result.iconUrl;
    const name = details?.name || '';
    inputField.value = name;

    const latLng =  details?.geometry?.location ? {
      lat: details.geometry.location.lat(),
      lng: details.geometry.location.lng()
    } : undefined;

    const location:ERTPLocation = {
      userProvidedName: name,
      googlePlaceId: placeId,
      googlePlaceTypes: details?.types,
      latLng, 
    };

    emit('update:modelValue', location);
    
  });
}


function updateLocation(userProvidedName: string, placeResult: google.maps.places.PlaceResult) {
  emit('update:modelValue', fromPlaceResult(userProvidedName, placeResult));
}

function initializeWithGoogleAutocomplete(google: typeof globalThis.google, inputField: HTMLInputElement) {

  const autocomplete = new google.maps.places.Autocomplete(inputField, {
    fields: GOOGLE_AUTOCOMPLETE_INIT_FIELDS,
    types: GOOGLE_AUTOCOMPLETE_TYPE_CONSTRAINT
  });

  autocomplete.addListener('place_changed', () => {
    const placeResult = autocomplete.getPlace();
    if (placeResult.place_id) updateLocation(inputField.value, placeResult);
  });

  if (!props.modelValue.userProvidedName) {
    // start with an empty string, if needed
    emit('update:modelValue', {userProvidedName: ''});
  }
}

function initializeLocation() {

  if (!locationInput.value) return;
  if (props.modelValue.userProvidedName) { 
    // Already have a caller-specified location
    locationInput.value.value = props.modelValue.userProvidedName;
    return;
  };

  // We'll need to get the inital location from Google.

  if (googleService.isLoading.value) { console.log('Waiting for google to load...'); return; };
  const google = googleService.googleRef.value;
  // TODO(tjohns): Fill in placeholder or error message when Google fails to load
  if (!google) { console.log('Google Maps libraries failed to load...'); return ; }

  icon.value = undefined;
  iconUrl.value = undefined;

  if (props.readOnly && props.gpid) {
    initializeWithGooglePlaceId(locationInput.value, props.gpid);
  } else {
    initializeWithGoogleAutocomplete(google, locationInput.value);
  }
}

watch([
  googleService.isLoading,
  attrContainer, 
  locationInput,
  () => props.gpid
], initializeLocation, {immediate: true});


</script>

<style>
.pac-container {
  z-index: 1075 !important;
}

input.ertp-form-control {
    display: block;
    width: 100%;
    height: 39px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-top-left-radius: var(--bs-border-radius);
    border-bottom-left-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.ertp-calendar-button {
  height: 39px;
  width: 39px;
}

</style>